import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import {
  BlogAuthor,
  Breadcrumb,
  Comments,
  FirebaseStorageImage,
} from "../../components";
import { getBlogAsync } from "../../services/blog";
import "./Blog.css";
import GithubGist from "../../components/github-gist/GithubGist";

export function Blog(props) {
  const { id } = useParams();
  const [blog, setBlog] = useState(null);
  useEffect(() => {
    getBlogAsync(id)
      .then((blog) => {
        setBlog(blog);
        console.log(blog);
      })
      .catch((error) => {
        console.error("unable to fetch blog");
        console.error(error);
      });
  }, [id]);

  return !blog ? (
    <div></div>
  ) : (
    <React.Fragment>
      <Breadcrumb {...props.breadcrumb} />
      <section id="blog" className="blog">
        <div className="container" data-aos="fade-up">
          <div className="row">
            <div className="col-lg-8 entries">
              <article className="entry entry-single">
                <div className="entry-img">
                  <FirebaseStorageImage
                    path={`${blog.id}/${blog.image}`}
                    alt="blog entry image"
                  />
                </div>

                <h2 className="entry-title">
                  <a href="#">{blog.title}</a>
                </h2>

                <div className="entry-meta">
                  <ul>
                    <li className="d-flex align-items-center">
                      <i className="bi bi-person"></i>{" "}
                      <a href="blog-single.html">{blog.an}</a>
                    </li>
                    <li className="d-flex align-items-center">
                      <i className="bx bx-calendar-edit"></i>{" "}
                      <a href="blog-single.html">
                        <time dateTime={blog.createdOn.toDate().toString()}>
                          {blog.createdOn.toDate().toDateString()}
                        </time>
                      </a>
                    </li>
                    <li className="d-flex align-items-center">
                      <i className="bi bi-clock"></i>{" "}
                      <a href="#">{`${blog.rt.min}min ${blog.rt.sec}sec`}</a>
                    </li>
                  </ul>
                </div>

                <div className="entry-content">
                  {blog.parts.map((part, index) =>
                    getArticlePart(part.t, part.v, index)
                  )}
                  <GithubGist
                    gist="4e8c43aa124b476e31fa0a5aa91bc584"
                    file="FormattingCheck.jsx"
                  />
                </div>

                <div className="entry-footer">
                  {/* <i className="bi bi-folder"></i>
                  <ul className="cats">
                    <li>
                      <a href="#">{blog.category}</a>
                    </li>
                  </ul> */}

                  <i className="bi bi-tags"></i>
                  <ul className="tags">
                    {blog.tags.map((tag, index) => {
                      return (
                        <li key={index}>
                          <a href="#">{tag}</a>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </article>
              <BlogAuthor author={blog.author} />
              {/* <Comments /> */}
            </div>

            <div className="col-lg-4">
              <div className="sidebar">
                <h3 className="sidebar-title">Search</h3>
                <div className="sidebar-item search-form">
                  <form action="">
                    <input type="text" />
                    <button type="submit">
                      <i className="bi bi-search"></i>
                    </button>
                  </form>
                </div>

                <h3 className="sidebar-title">Categories</h3>
                <div className="sidebar-item categories">
                  <ul>
                    <li>
                      <a href="#">
                        General <span>(25)</span>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        Lifestyle <span>(12)</span>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        Travel <span>(5)</span>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        Design <span>(22)</span>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        Creative <span>(8)</span>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        Educaion <span>(14)</span>
                      </a>
                    </li>
                  </ul>
                </div>

                <h3 className="sidebar-title">Recent Posts</h3>
                <div className="sidebar-item recent-posts">
                  <div className="post-item clearfix">
                    <img src="assets/img/blog/blog-recent-1.jpg" alt="" />
                    <h4>
                      <a href="blog-single.html">
                        Nihil blanditiis at in nihil autem
                      </a>
                    </h4>
                    <time dateTime="2020-01-01">Jan 1, 2020</time>
                  </div>

                  <div className="post-item clearfix">
                    <img src="assets/img/blog/blog-recent-2.jpg" alt="" />
                    <h4>
                      <a href="blog-single.html">Quidem autem et impedit</a>
                    </h4>
                    <time dateTime="2020-01-01">Jan 1, 2020</time>
                  </div>

                  <div className="post-item clearfix">
                    <img src="assets/img/blog/blog-recent-3.jpg" alt="" />
                    <h4>
                      <a href="blog-single.html">
                        Id quia et et ut maxime similique occaecati ut
                      </a>
                    </h4>
                    <time dateTime="2020-01-01">Jan 1, 2020</time>
                  </div>

                  <div className="post-item clearfix">
                    <img src="assets/img/blog/blog-recent-4.jpg" alt="" />
                    <h4>
                      <a href="blog-single.html">
                        Laborum corporis quo dara net para
                      </a>
                    </h4>
                    <time dateTime="2020-01-01">Jan 1, 2020</time>
                  </div>

                  <div className="post-item clearfix">
                    <img src="assets/img/blog/blog-recent-5.jpg" alt="" />
                    <h4>
                      <a href="blog-single.html">
                        Et dolores corrupti quae illo quod dolor
                      </a>
                    </h4>
                    <time dateTime="2020-01-01">Jan 1, 2020</time>
                  </div>
                </div>

                <h3 className="sidebar-title">Tags</h3>
                <div className="sidebar-item tags">
                  <ul>
                    <li>
                      <a href="#">App</a>
                    </li>
                    <li>
                      <a href="#">IT</a>
                    </li>
                    <li>
                      <a href="#">Business</a>
                    </li>
                    <li>
                      <a href="#">Mac</a>
                    </li>
                    <li>
                      <a href="#">Design</a>
                    </li>
                    <li>
                      <a href="#">Office</a>
                    </li>
                    <li>
                      <a href="#">Creative</a>
                    </li>
                    <li>
                      <a href="#">Studio</a>
                    </li>
                    <li>
                      <a href="#">Smart</a>
                    </li>
                    <li>
                      <a href="#">Tips</a>
                    </li>
                    <li>
                      <a href="#">Marketing</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}

function getArticlePart(t, v, index) {
  switch (t) {
    case "i":
      return <FirebaseStorageImage key={index} path={v} alt={v} />;
    case "p":
      return <p key={index}>{v}</p>;
    case "sh":
      return <h3 key={index}>{v}</h3>;
    case "bq":
      return (
        <blockquote key={index}>
          <p>{v}</p>
        </blockquote>
      );
    // case "cd":
    //   return <code key={index}>{v}</code>;
    // default:
    //   return <p key={index}>{v}</p>;
  }
}
