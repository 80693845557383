import React from "react";
import { Switch, Route } from "react-router-dom";
import Home from "../home";
import Blogs from "../blogs";
import {
  Blog,
  Portfolios,
  Services,
  About,
  Team,
  Testimonials,
  Contact,
  Portfolio,
  Pricing,
  Faq,
} from "../../containers";

export function LayoutRoterSwitch(props) {
  return (
    <Switch>
      <Route path="/home">
        <Home />
      </Route>
      <Route path="/blogs/:id">
        <Blog
          breadcrumb={{
            title: "",
            parts: [
              { value: "Home", url: "home" },
              { value: "Blog", url: "blogs", abs: true },
            ],
          }}
        />
      </Route>
      <Route path="/blogs" exact={true}>
        <Blogs
          breadcrumb={{
            title: "Blog",
            parts: [{ value: "Home", url: "home" }, { value: "Blog" }],
          }}
        />
      </Route>
      <Route path="/portfolios/:id">
        <Portfolio
          breadcrumb={{
            title: "Portfolio",
            parts: [
              { value: "Home", url: "home" },
              { value: "Portfolio", url: "portfolios" },
              { param: "id" },
            ],
          }}
        />
      </Route>
      <Route path="/portfolios" exact={true}>
        <Portfolios
          breadcrumb={{
            title: "Portfolio",
            parts: [{ value: "Home", url: "home" }, { value: "Portfolio" }],
          }}
        />
      </Route>
      <Route path="/services">
        <Services
          breadcrumb={{
            title: "Services",
            parts: [{ value: "Home", url: "home" }, { value: "Services" }],
          }}
        />
      </Route>
      <Route path="/about">
        <About
          breadcrumb={{
            title: "About",
            parts: [{ value: "Home", url: "home" }, { value: "About" }],
          }}
        />
      </Route>
      <Route path="/team">
        <Team
          breadcrumb={{
            title: "Team",
            parts: [{ value: "Home", url: "home" }, { value: "Team" }],
          }}
        />
      </Route>
      <Route path="/pricing">
        <Pricing
          breadcrumb={{
            title: "Pricing",
            parts: [{ value: "Home", url: "home" }, { value: "Pricing" }],
          }}
        />
      </Route>
      <Route path="/testimonials">
        <Testimonials
          breadcrumb={{
            title: "Testimonials",
            parts: [{ value: "Home", url: "home" }, { value: "Testimonials" }],
          }}
        />
      </Route>
      <Route path="/contact">
        <Contact
          breadcrumb={{
            title: "Contact",
            parts: [{ value: "Home", url: "home" }, { value: "Contact" }],
          }}
        />
      </Route>
      <Route path="/faq">
        <Faq
          breadcrumb={{
            title: "FAQ",
            parts: [{ value: "Home", url: "home" }, { value: "FAQ" }],
          }}
        />
      </Route>
      <Route path="/">
        <Home />
      </Route>
    </Switch>
  );
}
