import React from "react";

export function Team() {
  return (
    <section id="team" class="team ">
      <div class="container">
        <div class="row">
          <div class="col-lg-6">
            <div class="member d-flex align-items-start">
              <div class="pic">
                <img
                  src="assets/img/team/team-1.jpg"
                  class="img-fluid"
                  alt=""
                />
              </div>
              <div class="member-info">
                <h4>Walter White</h4>
                <span>Chief Executive Officer</span>
                <p>Explicabo voluptatem mollitia et repellat</p>
                <div class="social">
                  <a href="">
                    <i class="ri-twitter-fill"></i>
                  </a>
                  <a href="">
                    <i class="ri-facebook-fill"></i>
                  </a>
                  <a href="">
                    <i class="ri-instagram-fill"></i>
                  </a>
                  <a href="">
                    {" "}
                    <i class="ri-linkedin-box-fill"></i>{" "}
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-6 mt-4 mt-lg-0">
            <div class="member d-flex align-items-start">
              <div class="pic">
                <img
                  src="assets/img/team/team-2.jpg"
                  class="img-fluid"
                  alt=""
                />
              </div>
              <div class="member-info">
                <h4>Sarah Jhonson</h4>
                <span>Product Manager</span>
                <p>Aut maiores voluptates amet et quis</p>
                <div class="social">
                  <a href="">
                    <i class="ri-twitter-fill"></i>
                  </a>
                  <a href="">
                    <i class="ri-facebook-fill"></i>
                  </a>
                  <a href="">
                    <i class="ri-instagram-fill"></i>
                  </a>
                  <a href="">
                    {" "}
                    <i class="ri-linkedin-box-fill"></i>{" "}
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-6 mt-4">
            <div class="member d-flex align-items-start">
              <div class="pic">
                <img
                  src="assets/img/team/team-3.jpg"
                  class="img-fluid"
                  alt=""
                />
              </div>
              <div class="member-info">
                <h4>William Anderson</h4>
                <span>CTO</span>
                <p>Quisquam facilis cum velit laborum corrupti</p>
                <div class="social">
                  <a href="">
                    <i class="ri-twitter-fill"></i>
                  </a>
                  <a href="">
                    <i class="ri-facebook-fill"></i>
                  </a>
                  <a href="">
                    <i class="ri-instagram-fill"></i>
                  </a>
                  <a href="">
                    {" "}
                    <i class="ri-linkedin-box-fill"></i>{" "}
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-6 mt-4">
            <div class="member d-flex align-items-start">
              <div class="pic">
                <img
                  src="assets/img/team/team-4.jpg"
                  class="img-fluid"
                  alt=""
                />
              </div>
              <div class="member-info">
                <h4>Amanda Jepson</h4>
                <span>Accountant</span>
                <p>Dolorum tempora officiis odit laborum officiis</p>
                <div class="social">
                  <a href="">
                    <i class="ri-twitter-fill"></i>
                  </a>
                  <a href="">
                    <i class="ri-facebook-fill"></i>
                  </a>
                  <a href="">
                    <i class="ri-instagram-fill"></i>
                  </a>
                  <a href="">
                    {" "}
                    <i class="ri-linkedin-box-fill"></i>{" "}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
