import React from "react";
import { Link } from "react-router-dom";
import "./Breadcrumb.css";

export function Breadcrumb(props) {
  return (
    <section id="breadcrumbs" className="breadcrumbs">
      <div className="container">
        <div className="d-flex justify-content-between align-items-center">
          <h2>{props.title}</h2>
          <ol>
            {getBreadcrumbPart(props.parts, props.params, props.queryParams)}
          </ol>
        </div>
      </div>
    </section>
  );
}

function getBreadcrumbPart(parts, params, queryParams) {
  let lastUrl = "";
  const crumbs = parts.map((part, index) => {
    lastUrl += "/" + part.url;
    return (
      <li key={index}>
        {part.url ? (
          <Link to={part.abs ? `/${part.url}` : lastUrl}>{part.value}</Link>
        ) : (
          part.value
        )}
      </li>
    );
  });
  return crumbs;
}
