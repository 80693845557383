import React from "react";
import { getNavigationMenuItems, isActive } from "../../services/layout";

export function NavigationMenu(props) {
  return <ul>{Level1Menu(getNavigationMenuItems().items)}</ul>;
}

function Level1Menu(items) {
  if (Array.isArray(items) && items.length) {
    return items.map((item, index) => {
      if (item.subMenu) {
        return (
          <li className="dropdown" key={index}>
            <a href={item.href}>
              <span>{item.text}</span> <i className="bi bi-chevron-down"></i>
            </a>
            <ul>{Level2Menu(item.subMenu.items)}</ul>
          </li>
        );
      }
      return (
        <li key={index}>
          <a href={item.link} className={isActive() ? "active" : ""}>
            {item.text}
          </a>
        </li>
      );
    });
  }
}

function Level2Menu(items) {
  if (Array.isArray(items) && items.length) {
    return items.map((item, index) => {
      if (item.subMenu) {
        return (
          <li className="dropdown" key={index}>
            <a href={item.href}>
              <span>{item.text}</span> <i className="bi bi-chevron-right"></i>
            </a>
            <ul>{Level3Menu(item.subMenu.items)}</ul>
          </li>
        );
      }
      return (
        <li key={index}>
          <a href={item.link} className={isActive() ? "active" : ""}>
            {item.text}
          </a>
        </li>
      );
    });
  }
}

function Level3Menu(items) {
  if (Array.isArray(items) && items.length) {
    return items.map((item, index) => {
      return (
        <li key={index}>
          <a href={item.link}>{item.text}</a>
        </li>
      );
    });
  }
}
