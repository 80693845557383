import React from "react";
import { Breadcrumb } from "../../components";
import { ServicesTiles } from "./ServicesTiles";

export function Services(props) {
  return (
    <React.Fragment>
      {props.breadcrumb && <Breadcrumb {...props.breadcrumb} />}
      <ServicesTiles />
     
    </React.Fragment>
  );
}
