import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FirebaseStorageImage } from "../../components";

export function BlogSummary(props) {
  const blog = props.blog;

  return (
    <article className="entry">
      <div className="entry-img">
        <FirebaseStorageImage
          path={`${blog.id}/${blog.image}`}
          alt="blog entry image"
        />
      </div>

      <h2 className="entry-title">
        <a href="blog-single.html">{blog.title}</a>
      </h2>

      <div className="entry-meta">
        <ul>
          <li className="d-flex align-items-center">
            <i className="bi bi-person"></i>{" "}
            {/* <a href="blog-single.html">{blog.author}</a> */}
          </li>
          <li className="d-flex align-items-center">
            <i className="bx bx-calendar-edit"></i>{" "}
            <a href="blog-single.html">
              <time datetime={blog.createdOn.toDate().toString()}>
                {blog.createdOn.toDate().toDateString()}
              </time>
            </a>
          </li>
          <li className="d-flex align-items-center">
            <i className="bi bi-chat-dots"></i>{" "}
            <a href="blog-single.html">12 Comments</a>
          </li>
        </ul>
      </div>

      <div className="entry-content">
        <p>{blog.excerpt}</p>
        <div className="read-more">
          <Link to={`/blogs/${blog.id}`}>Read More</Link>
          {/* <a href="blog-single.html">Read More</a> */}
        </div>
      </div>
    </article>
  );
}
