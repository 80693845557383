import React from "react";
import { LayoutRoterSwitch } from "./LayoutRouterSwitch";
import { NavigationMenu } from "./NavigationMenu";
import "./Layout.css";
import { Footer } from "./Footer";
import { Header } from "./Header";
export function Layout(props) {
  return (
    <div>
      <Header>
        <NavigationMenu />
      </Header>
      <LayoutRoterSwitch />
      <Footer />
      <a
        href="#"
        className="back-to-top d-flex align-items-center justify-content-center"
      >
        <i className="bi bi-arrow-up-short"></i>
      </a>
    </div>
  );
}
