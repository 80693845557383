// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export const firebaseConfig = {
  apiKey: "AIzaSyBRu4kQrjmiVeZ9QGELaP6ghCyPr7NkZQs",
  authDomain: "nullsoft-in.firebaseapp.com",
  databaseURL: "https://nullsoft-in.firebaseio.com",
  projectId: "nullsoft-in",
  storageBucket: "nullsoft-in.appspot.com",
  messagingSenderId: "883434375398",
  appId: "1:883434375398:web:d3764496b5f67fc23c9327",
  measurementId: "G-B0GWZ4M5XW",
};
