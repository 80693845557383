import React from "react";

export function ServicesTiles() {
  return (
    <section id="services" className="services">
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <div className="icon-box">
              <i className="bx bx-desktop"></i>
              <h4>
                <a href="#">Application Service &amp; Modernization</a>
              </h4>
              <p>
                Smart DevOps driven application development which ensures less
                time to market. Modernization of existing applications to make
                your portfolio digital ready.
              </p>
            </div>
          </div>
          <div className="col-md-6 mt-4 mt-md-0">
            <div className="icon-box">
              <i className="bi bi-cloud"></i>
              <h4>
                <a href="#">Cloud Enablement</a>
              </h4>
              <p>
                Experienced architects to help you choose the right strategy and
                cost effective solutions.
              </p>
            </div>
          </div>
          <div className="col-md-6 mt-4 mt-md-0">
            <div className="icon-box">
              <i class="bx bx-vial"></i>
              <h4>
                <a href="#">Test Automation</a>
              </h4>
              <p>
                Support your business objectives by designing an test automation
                strategy aligned with enterprise goals.
              </p>
            </div>
          </div>
          <div className="col-md-6 mt-4 mt-md-0">
            <div className="icon-box">
              <i class="bx bx-line-chart"></i>
              <h4>
                <a href="#">Analytics &amp; Report</a>
              </h4>
              <p>
                Experts with experience in relevant industries to work closely
                with you to help you achieve economies of scale. Analytics to
                help you make key business decisions.
              </p>
            </div>
          </div>
          <div className="col-md-6 mt-4 mt-md-0">
            <div className="icon-box">
              <i class="bx bx-diamond"></i>
              <h4>Premium On Demand Consultation</h4>
              <p>
                Existing team need help ? On demand consultation from industry
                experts to help your team to overcome any situation.
              </p>
            </div>
          </div>
          <div className="col-md-6 mt-4 mt-md-0">
            <div className="icon-box">
              <i class="bx bx-user-voice"></i>
              <h4>Training</h4>
              <p>
                Upskill your existing team to improve productivity, retention
                and morale.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
