export function getNavigationMenuItems() {
  return navigationMenuItems;
}

export function isActive(url, menuItem) {
  return false;
}
const navigationMenuItems = {
  level: 1,
  items: [
    {
      text: "Home",
      link: "/home",
    },
    {
      text: "Services",
      link: "/services",
    },
    // {
    //   text: "Portfolio",
    //   link: "/portfolios",
    // },
    // {
    //   text: "Pricing",
    //   link: "/pricing",
    // },
    {
      text: "Blog",
      link: "/blogs",
    },
    // {
    //   text: "About",
    //   link: "/about",
    //   subMenu: {
    //     level: 2,
    //     items: [
    //       {
    //         text: "About",
    //         link: "/about",
    //       },
    //       {
    //         text: "Team",
    //         link: "/team",
    //       },
    //       {
    //         text: "Testimonials",
    //         link: "/testimonials",
    //       },
    //       // {
    //       //   text: "L3 Menu",
    //       //   link: "#",
    //       //   subMenu: {
    //       //     level: 3,
    //       //     items: [
    //       //       {
    //       //         text: "About",
    //       //         link: "/about",
    //       //       },
    //       //       {
    //       //         text: "Team",
    //       //         link: "/team",
    //       //       },
    //       //     ],
    //       //   },
    //       // },
    //     ],
    //   },
    // },
    {
      text: "Contact",
      link: "/contact",
    },
  ],
};
