import firebase from "firebase/app";
import {
  getFirestore,
  getStorageRegerence,
  getStorageBucketRoot,
} from "../../firebase/firebase.service";
const blogsCache = {};
const authorsCache = {};
export async function getMostRecentBlogs(start, count) {
  start = start || 0;
  count = count || 5;
  const blogsRef = getFirestore().collection("blogs");
  return blogsRef
    .orderBy("createdOn")
    .startAt(start)
    .limit(count)
    .get()
    .then((querySnapshot) => {
      const blogs = [];
      querySnapshot.forEach((doc) => {
        const blog = doc.data();
        blogsCache[blog.id] = blog;
        blogs.push(blog);
      });
      return blogs;
    });
}

export function getBlogAsync(id) {
  return new Promise((resolve, reject) => {
    const cachedBlog = lookupBlogCache(id);
    if (cachedBlog) {
      return cachedBlog;
    }
    getFirestore()
      .collection("blogs")
      .doc(id)
      .get()
      .then((doc) => {
        if (doc.exists) {
          const blog = doc.data();
          Promise.all([
            blog.partsRef.get().then((response) => response.data()),
            getAuthor(blog.aid),
          ])
            .then(([parts, author]) => {
              blog.parts = parts.parts;
              blog.author = author;
              blogsCache[id] = blog;
              resolve(blogsCache[id]);
            })
            .catch(reject);
        }
      })
      .catch(reject);
  });
}

function lookupBlogCache(id) {
  if (blogsCache[id]) {
    return blogsCache.id;
  }
}

function getAuthor(id) {
  return new Promise((resolve, reject) => {
    if (authorsCache[id]) {
      resolve(authorsCache[id]);
    }
    getFirestore()
      .collection("authors")
      .doc(id)
      .get()
      .then((doc) => {
        if (doc.exists) {
          authorsCache[id] = doc.data();
        }
        resolve(authorsCache[id]);
      });
  });
}
