import firebase from "firebase/app";
import "firebase/analytics";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import { firebaseConfig } from "./config.firebase";

let instance;
let store;
let storageRef;
export function initalizeFirebase() {
  if (instance) {
    return;
  }
  firebase.initializeApp(firebaseConfig);
  firebase.analytics();
  instance = true;
}

export function getFirestore() {
  if (!store) {
    store = firebase.firestore();
  }
  return store;
}

export function getStorageRegerence() {
  if (!storageRef) {
    storageRef = firebase.storage().ref();
  }
  return storageRef;
}

export function getStorageBucketRoot(storageRef) {
  return storageRef.root;
}

export function getFirebaseImageUrl(path) {
  return `https://firebasestorage.googleapis.com/v0/b/nullsoft-in.appspot.com/o/${encodeURIComponent(
    path
  )}?alt=media`;
}

initalizeFirebase();
