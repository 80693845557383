import React from "react";

export function BlogAuthor({ author }) {
  return (
    <div className="blog-author d-flex align-items-center">
      <img src={author.pic} className="rounded-circle float-left" alt="" />
      <div>
        <h4>{`${author.fn} ${author.ln}`}</h4>
        <div className="social-links">
          {author.twt && (
            <a href={`https://twitter.com/${author.twt}`} target="_blank">
              <i className="bi bi-twitter"></i>
            </a>
          )}
          {author.git && (
            <a href={`https://github.com/${author.git}`} target="_blank">
              <i className="bx bxl-github"></i>
            </a>
          )}
          {author.in && (
            <a
              href={`https://www.linkedin.com/in/${author.in}`}
              target="_blank"
            >
              <i class="bx bxl-linkedin-square"></i>
            </a>
          )}
          {/* <a href="https://facebook.com/#">
            <i className="bi bi-facebook"></i>
          </a> */}
          {/* <a href="https://instagram.com/#">
            <i className="biu bi-instagram"></i>
          </a> */}
        </div>
        <p>{author.bio}</p>
      </div>
    </div>
  );
}
