export function getCarouselBannerItems() {
  return carouselBannerItems;
}

const carouselBannerItems = [
  {
    backgroundImage: "assets/img/home/banner/slide-4.jpg",
    heading: "Unlock the Power of Cloud",
    subHeading:
      "We can help you levarage cloud to enhance customer experience and improve time to market",
    buttonURL: "#about",
    buttonText: "Read More",
    isActive: true,
  },
  {
    backgroundImage: "assets/img/home/banner/slide-2.jpg",
    heading: "Making it Work for You",
    subHeading:
      "We are proud to be partner with companies/individuals in creating value in their respective industries",
    buttonURL: "#about",
    buttonText: "Read More",
  },
  {
    backgroundImage: "assets/img/home/banner/slide-3.jpg",
    heading: "Insight & Control",
    subHeading: "Get insights that can accelerate and enrich decision-making.",
    buttonURL: "#about",
    buttonText: "Read More",
  },
];
