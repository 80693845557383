import React from "react";

export function Header(props) {
  return (
    <header id="header" className="fixed-top d-flex align-items-center">
      <div className="container d-flex align-items-center">
        <h1 className="logo me-auto">
          <a href="/">nullsoft.in</a>
        </h1>
        {/* <a href="/" className="logo me-auto">
          <img src="/favicon-32x32.png" alt="" className="img-fluid" />
        </a> */}

        <nav id="navbar" className="navbar">
          {props.children}
        </nav>
      </div>
    </header>
  );
}
