import React, { useState } from "react";
import logo from "./logo.svg";
import "./App.css";
import "./animations/index.css";
import Layout from "./containers/layout";
import Home from "./containers/home";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Portfolio } from "./containers";
import { initalizeFirebase } from "./firebase/firebase.service";
import { useEffect } from "react";
import firebase from "firebase/app";

function App() {
  const [setupDone, setSetupDone] = useState(false);
  initalizeFirebase();
  useEffect(() => {
    firebase
      .auth()
      .signInAnonymously()
      .then(() => {
        console.log("ok");
      })
      .catch((error) => {
        console.log(error.code);
        console.log(error.message);
      }, []);
    setSetupDone(true);
  });
  return !setSetupDone ? (
    <div>Loading...</div>
  ) : (
    <div id="page-wrapper">
      <Router>
        <Switch>
          <Route path="/portfolio-no-layout/:id" exact={true}>
            <Portfolio />
          </Route>
          <Route path="/">
            <Layout>
              <Home />
            </Layout>
          </Route>
        </Switch>
      </Router>
    </div>
  );
}

export default App;
