import React from "react";
import "./Home.css";
import { CarouselBanner } from "../../components";
import { Portfolios } from "../../containers";
import { getCarouselBannerItems } from "../../services/home";
import { ServicesTiles } from "../services";

export function Home(props) {
  return (
    <div id="home">
      <section id="hero">
        <CarouselBanner items={getCarouselBannerItems()} />
      </section>

      <main id="main">
        <section id="about" className="about">
          <div className="container">
            <div className="row content">
              <div className="col-lg-6">
                <h2>
                  WHO WE ARE ? <br /> Why Choose Our Business Solutions?
                </h2>
                {/* <h3>
                  Voluptatem dignissimos provident quasi corporis voluptates sit
                  assum perenda sruen jonee trave
                </h3> */}
              </div>
              <div className="col-lg-6 pt-4 pt-lg-0">
                <p>
                  Our IT services converge business and technology experts to
                  help to manage business processes of all categories.
                </p>
                <ul>
                  <li>
                    <i className="ri-check-double-line"></i> We are committed to
                    providing quality IT Services
                  </li>
                  <li>
                    <i className="ri-check-double-line"></i> Professional &amp;
                    Experienced Team
                  </li>
                  <li>
                    <i className="ri-check-double-line"></i> Our Main Goal Is
                    Your Success
                  </li>
                </ul>
                <p className="fst-italic">
                  Our vertical solutions expertise allows your business to
                  streamline workflow, and increase productivity. No matter the
                  business, we got you covered with industry compliant
                  solutions, customized to your company’s specific needs.
                </p>
              </div>
            </div>
          </div>
        </section>

        {/* <section id="clients" className="clients section-bg">
          <div className="container">
            <div className="row">
              <div
                className="
            col-lg-2 col-md-4 col-6
            d-flex
            align-items-center
            justify-content-center
          "
              >
                <img
                  src="assets/img/clients/client-1.png"
                  className="img-fluid"
                  alt=""
                />
              </div>

              <div
                className="
            col-lg-2 col-md-4 col-6
            d-flex
            align-items-center
            justify-content-center
          "
              >
                <img
                  src="assets/img/clients/client-2.png"
                  className="img-fluid"
                  alt=""
                />
              </div>

              <div
                className="
            col-lg-2 col-md-4 col-6
            d-flex
            align-items-center
            justify-content-center
          "
              >
                <img
                  src="assets/img/clients/client-3.png"
                  className="img-fluid"
                  alt=""
                />
              </div>

              <div
                className="
            col-lg-2 col-md-4 col-6
            d-flex
            align-items-center
            justify-content-center
          "
              >
                <img
                  src="assets/img/clients/client-4.png"
                  className="img-fluid"
                  alt=""
                />
              </div>

              <div
                className="
            col-lg-2 col-md-4 col-6
            d-flex
            align-items-center
            justify-content-center
          "
              >
                <img
                  src="assets/img/clients/client-5.png"
                  className="img-fluid"
                  alt=""
                />
              </div>

              <div
                className="
            col-lg-2 col-md-4 col-6
            d-flex
            align-items-center
            justify-content-center
          "
              >
                <img
                  src="assets/img/clients/client-6.png"
                  className="img-fluid"
                  alt=""
                />
              </div>
            </div>
          </div>
        </section> */}

        <ServicesTiles />
        {/* <Portfolios /> */}
      </main>
    </div>
  );
}
